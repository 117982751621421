import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';

import axios from '../utils/axios';

const AgentSurveys = () => {
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const res = await axios.get('/api/leads/agentSurveys');
                setSurveys(res.data);
            } catch (err) {
                console.error(err);
            }
        };
        fetchSurveys().then(r => console.log(r));
    }, []);

    return (
        <div className='agent-surveys'>
            <Typography variant="h5">Agent Surveys</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell>Survey Title</TableCell>
                        <TableCell>Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {surveys.map((survey) => (
                        <TableRow key={survey._id}>
                            <TableCell>{survey.user}</TableCell>
                            <TableCell>{survey.comments}</TableCell>
                            <TableCell>{new Date(survey.createdAt).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default AgentSurveys;
