import React, {useEffect, useState} from "react";
import {
    Slide,
    Box,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button
} from "@mui/material";
import {Note as NoteIcon} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

import axios from "../utils/axios";

const LeadDetails = ({ leadId, leadStatuses }) => {
    const [selectedLead, setSelectedLead] = useState();
    const [assignedStatus, setAssignedStatus] = useState(null);
    const [message, setMessage] = useState(null);
    const [meetingDate, setMeetingDate] = useState(null);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const fetchLead = async (leadId) => {
        const lead = await axios.get(`/api/leads/${leadId}`)
        setSelectedLead(lead.data);
        setAssignedStatus(lead.data?.assignedStatus?._id);
    };

    const handleAssignedStatusChange = async (e) => {
        console.log('assigned status', e.target.value);
        setAssignedStatus(e.target.value);
    };
    const handleClose = () => {
        setSelectedLead(false);
        navigate("/leads");
    };

    const handleDateChange = async (e) => {
        setMeetingDate(e.target.value);
    };


    const fetchNotes = async () => {
        try {
            const res = await axios.get(`/api/leads/${leadId}/notes`);
            setNotes(res.data);
        } catch (err) {
            console.error(err);
            alert('Failed to fetch notes.');
        }
    };

    const handleAssignStatus = async () => {
        try {
            await axios.put(`/api/leads/${selectedLead._id}/assign-status`, {
                status: assignedStatus,
                meetingDate: meetingDate
            });
            const statusId = assignedStatus;
            const leadStatusIdx = leadStatuses.findIndex(e => e._id === statusId);
            selectedLead.assignedStatus = leadStatuses[leadStatusIdx];
        } catch (err) {
            console.error(err);
            setMessage('Failed to set status');
        }
    };

    useEffect(() => {
        if(leadId) {
            fetchLead(leadId);
            fetchNotes(leadId);
        }
        return () => {};
    }, [leadId]);

    return (!!selectedLead &&
        <Slide direction="down" in={!!selectedLead} mountOnEnter>
                <Box
                    sx={{
                        maxWidth: "100%",
                        backgroundColor: "lightyellow",
                        boxShadow: 3,
                        padding: 2
                    }}
                >
                    <Box sx={{
                        position: "relative"
                    }}>
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    </Box>
                    <h3>Lead Details</h3>
                    <Typography variant="h6">{selectedLead.name}</Typography>
                    <Typography>Email: {selectedLead.email}</Typography>
                    <Typography>Phone: {selectedLead.phone}</Typography>
                    <Typography>Municipality: {selectedLead.municipality}</Typography>
                    <Box marginTop={2} maxWidth={300}>
                        <div>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="assignedStatusSelect-label" shrink>Assign status:</InputLabel>
                                <Select
                                    labelId="assignedStatusSelect-label"
                                    id="assignedStatusSelect"
                                    value={assignedStatus || selectedLead.assignedStatus}
                                    onChange={handleAssignedStatusChange}>
                                    <MenuItem key="" value="">no status assigned</MenuItem>
                                    {leadStatuses.map((status) => (
                                        <MenuItem key={status._id} value={status._id}>
                              <span style={{
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor: status.colour,
                                  display: 'inline-block',
                                  border: '1px solid #000',
                                  margin: '0 10px 0 0'
                              }} />{status.name.toLowerCase().replace('/', ' / ')}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {(assignedStatus === "67444c940c041b10602cdc1a") && (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="statusDate"
                                    label="Date of Planned Meeting"
                                    type="datetime-local"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleDateChange}
                                />
                            )}
                            <Button
                                onClick={handleAssignStatus}
                                variant="contained"
                                color="primary"
                                sx={{ marginBottom: 2 }}
                            >
                                set status
                            </Button>
                        </div>
                    </Box>
                    <Typography variant="h6">Notes:</Typography>
                    <Box component="ul" sx={{ padding: 0, listStyleType: 'none' }}>
                    {notes
                        ?.filter((log) => log.type === 'note' || log.type === 'review note')
                        ?.map((note) => (
                            <Box
                                key={note._id}
                                component="li"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '5px',
                                    marginBottom: '5px',
                                    backgroundColor: '#ffffcc',
                                    borderRadius: '5px',
                                }}
                            >
                                <NoteIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2">
                                    {note.message} -{' '}
                                    {new Date(note.createdAt).toLocaleString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Slide>
    );
};

export default LeadDetails;
