import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Box, Card, CardContent, Typography, IconButton, Button, Chip, Stack } from '@mui/material';
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material';
import moment from 'moment';

const LeadsList = ({ 
  leads, 
  user, 
  handleLeadClick, 
  handleStatusChange 
}) => {
  const isMobile = useMediaQuery('(max-width:1055px)');

  if (!isMobile) return null;

  return (
    <Stack spacing={2}>
      {leads.map((lead) => (
        <Card 
          key={lead._id} 
          onClick={() => handleLeadClick(lead)}
          sx={{
            width: '100%',
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 3,
              transform: 'translateY(-2px)',
              transition: 'all 0.2s ease-in-out'
            }
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                {lead.name}
              </Typography>
              <Box>
                <span 
                  style={{
                    backgroundColor: lead.assignedStatus?.colour || "#fff",
                    border: '1px solid #000',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {lead.assignedStatus?.name?.replace('/', ' / ').toLowerCase() || "no status"}
                </span>
              </Box>
            </Box>

            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EmailIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                <Typography variant="body2">{lead.email}</Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PhoneIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                <Typography variant="body2">{lead.phone}</Typography>
              </Box>

              <Typography variant="body2" color="text.secondary">
                Municipality: {lead.municipality}
              </Typography>

              {user?.role === 'admin' && lead.assignedAgent && (
                <Typography variant="body2" color="text.secondary">
                  Agent: {lead.assignedAgent.username}
                  {lead.assignedAgent.isRestricted && ' (Restricted)'}
                </Typography>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Created: {moment(lead.createdAt).format('lll')}
                </Typography>
                <Chip 
                  label={lead.status} 
                  size="small"
                  color={lead.status === 'Pending' ? 'warning' : lead.status === 'Accepted' ? 'success' : 'default'}
                />
              </Box>

              {user.role === 'agent' && lead.status === 'Pending' && (
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusChange(lead, 'Accepted');
                    }}
                    fullWidth
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusChange(lead, 'Rejected');
                    }}
                    fullWidth
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

export default LeadsList;