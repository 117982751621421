import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  NavLink,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Box,
  AppBar,
  Toolbar,
  Button,
} from '@mui/material';
import {
  Person as PersonIcon,
  Settings as SettingsIcon,
  AccountCircle as AccountCircleIcon,
  Language as LanguageIcon,
  CalendarToday as CalendarTodayIcon,
  Poll as PollIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import Dashboard from './components/Dashboard';
import Leads from './components/Leads';
import Agents from './components/Agents';
import Notifications from './components/Notifications';
import Website from './components/Website';
import Login from './components/Login';
import Log from './components/Log';
import { useAuth } from './AuthContext';
import Profile from './components/Profile';
import EmailCampaigns from './components/EmailCampaigns';
import Subscribers from './components/Subscribers';
import AgentSurvey from './components/AgentSurvey';
import LeadSurvey from './components/LeadSurvey';
import SurveyForms from './components/SurveyForms';
import ForgotPassword from './components/ForgotPassword';
import HolidayPlanner from "./components/HolidayCalendarPlanner";
import axios from './utils/axios';
import AdminSurveysView from "./components/AdminSurveysView";

const ProtectedRoute = ({ children, roles }) => {
  const { user } = useAuth();
  const storedUser = JSON.parse(localStorage.getItem('user'));

  if (!user && !storedUser) {
    return <Navigate to='/' />;
  }
  if (roles && !roles.includes(user ? user.role : storedUser.role)) {
    return <Navigate to='/leads' />;
  }
  return children;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, logout } = useAuth();
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const checkOverdueSurveys = async () => {
      try {
        const res = await axios.get('/api/leads/myPendingLeads');
        const leads = res.data;
        const overdue = leads.some((lead) => lead.lockoutApplies);
        if (
          overdue &&
          location.pathname !== '/surveyForms' &&
          !location.pathname.startsWith('/leadSurvey')
        ) {
          navigate('/surveyForms');
        }
      } catch (err) {
        console.error('Error checking overdue surveys:', err);
      }
    };

    if (user?.role === 'agent' || storedUser?.role === 'agent') {
      checkOverdueSurveys();
    }
  }, [user, storedUser, navigate, location.pathname]);

  useEffect(() => {
    if (!user && storedUser) {
      setUser(storedUser);
    }
  }, [user, storedUser, setUser]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <div>
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <img src="logo.webp" alt="Logo" style={{ maxWidth: '80%', marginTop: '70px' }} />
      </Box>
      <Divider />
      <List sx={{ backgroundColor: '#f7f7f7' }}> {/* Add this line */}
        <ListItem component={NavLink} to="/leads" onClick={handleDrawerToggle}>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary="Leads" />
        </ListItem>
        {(user?.role === 'admin' || storedUser?.role === 'admin') && (
          <ListItem component={NavLink} to="/agents" onClick={handleDrawerToggle}>
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary="Agents" />
          </ListItem>
        )}
        {/*{(user?.role === 'admin' || storedUser?.role === 'admin') && (*/}
        {/*  <ListItem component={NavLink} to="/agents/surveys" onClick={handleDrawerToggle}>*/}
        {/*    <ListItemIcon><PersonIcon /></ListItemIcon>*/}
        {/*    <ListItemText primary="Agents Surveys" />*/}
        {/*  </ListItem>*/}
        {/*)}*/}
        <ListItem component={NavLink} to="/notifications" onClick={handleDrawerToggle}>
          <ListItemIcon><LanguageIcon /></ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
        {(user?.role === 'admin' || storedUser?.role === 'admin') && (
          <ListItem component={NavLink} to="/logs" onClick={handleDrawerToggle}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Log" />
          </ListItem>
        )}
        <ListItem component={NavLink} to="/profile" onClick={handleDrawerToggle}>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem component={NavLink} to="/unavailability-planner" onClick={handleDrawerToggle}>
          <ListItemIcon><CalendarTodayIcon /></ListItemIcon>
          <ListItemText primary="Unavailability Planner" />
        </ListItem>
        {(user?.role === 'agent' || storedUser?.role === 'agent') && (
          <ListItem component={NavLink} to="/surveyForms" onClick={handleDrawerToggle}>
            <ListItemIcon><PollIcon /></ListItemIcon>
            <ListItemText primary="Survey Forms" />
          </ListItem>
        )}
      </List>
      <Divider />
      <Box sx={{ padding: 2 }}>
        <Typography variant="caption" display="block" gutterBottom>
          Version: {process.env.REACT_APP_VERSION}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {user && (
              <p>
            Logged in as {user?.username} ({(user?.role).charAt(0).toUpperCase() + (user?.role).slice(1)})
              </p>
            )}
        </Typography>
        <Button variant="contained" color="secondary" startIcon={<LogoutIcon />} onClick={logout}>
          Log out
        </Button>
      </Box>
    </div>
  );
  

  return (
    <Box sx={{ display: 'flex' }}>
      {(user || storedUser) && (
        <>
          <AppBar
  position="fixed"
  sx={{
    zIndex: (theme) => theme.zIndex.drawer + 1,
    boxShadow: 'none', // Remove the box shadow
    backgroundColor: '#2c576a', // Set the background color
  }}
>
  <Toolbar>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      sx={{ mr: 2, display: { md: 'none' } }} // Adjust breakpoint to 'md'
    >
      <MenuIcon />
    </IconButton>
    <Typography variant="h6" noWrap component="div">
      Modern Solution CRM
    </Typography>
  </Toolbar>
</AppBar>


<Drawer
  variant="permanent"
  sx={{
    display: { xs: 'none', md: 'block' }, // Change 'sm' to 'md' for 1055px breakpoint
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
  }}
  open
>
  {drawerContent}
</Drawer>
<Drawer
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true, // Better open performance on mobile.
  }}
  sx={{
    display: { xs: 'block', md: 'none' }, // Change 'sm' to 'md' for 1055px breakpoint
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
  }}
>
  {drawerContent}
</Drawer>
<Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    ml: { md: `240px` }, // Update the breakpoint to match
    mt: 8,
    width: { md: `calc(100% - 240px)` }, // Update the breakpoint to match
  }}
>
            <Routes>
              <Route
                path='/dashboard'
                element={
                  <ProtectedRoute roles={['admin', 'agent']}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                  path='/leads'
                  element={
                    <ProtectedRoute roles={['admin', 'agent']}>
                      <Leads />
                    </ProtectedRoute>
                  }
              />
              <Route
                  path='/leads/:leadId'
                  element={
                    <ProtectedRoute roles={['admin', 'agent']}>
                      <Leads />
                    </ProtectedRoute>
                  }
              />
              <Route
                path='/agents'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Agents />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/agents/surveys'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <AdminSurveysView />
                  </ProtectedRoute>
                }
              />
                            <Route
                path='/email-marketing/campaigns'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <EmailCampaigns />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/email-marketing/subscribers'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Subscribers />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/website'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Website />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/logs'
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Log />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/unavailability-planner'
                element={
                  <ProtectedRoute roles={['admin', 'agent']}>
                    <HolidayPlanner />
                  </ProtectedRoute>
                } />
              <Route
                path='/notifications'
                element={
                  <ProtectedRoute roles={['admin', 'agent']}>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/profile'
                element={
                  <ProtectedRoute roles={['admin', 'agent']}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/surveyForms'
                element={
                  <ProtectedRoute roles={['agent']}>
                    <SurveyForms />
                  </ProtectedRoute>
                }
              />
              <Route path='/agentSurvey/:leadId' element={<AgentSurvey />} />
              <Route
                path='/leadSurvey/:leadId'
                element={
                  <ProtectedRoute roles={['agent']}>
                    <LeadSurvey />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/'
                element={
                  user || storedUser ? <Navigate to='/leads' /> : <Login />
                }
              />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </Box>
        </>
      )}
      {!user && !storedUser && (
        <Routes>
          <Route path='/agentSurvey/:leadId' element={<AgentSurvey />} />
          <Route path='/' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
