import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import './Profile.css';
import { baseURL } from '../utils/constants';
import { Rating, Typography } from '@mui/material';

const Profile = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [emailMessage, setEmailMessage] = useState('');
  const [smsMessage, setSmsMessage] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    fetchProfileSettings();
  }, []);

  const fetchProfileSettings = async () => {
    try {
      const res = await axios.get('/api/users/me');
      const {
        profilePicture,
        emailMessage,
        smsMessage,
        email,
        phone,
        isSubscribed,
        role,
        rating,
      } = res.data;

      if (profilePicture) {
        setProfilePicture(`${baseURL}/${profilePicture}`);
      }

      setEmailMessage(emailMessage || "");
      setSmsMessage(smsMessage || "");
      setEmail(email);
      setPhone(phone);
      setIsAdmin(role === 'admin');
      setIsSubscribed(isSubscribed);
      setRating(rating);
    } catch (err) {
      console.error('Error fetching profile settings:', err);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert('Image size should be less than 1 MB');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      const fileInput = document.getElementById('profilePictureInput');
      if (fileInput.files[0] && fileInput.files[0].size > 1024 * 1024) {
        alert('Image size should be less than 1 MB');
        return;
      }
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('profilePicture', fileInput.files[0]);
      formData.append('emailMessage', emailMessage || "");
      formData.append('smsMessage', smsMessage || "");

      await axios.put('/api/users/me', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setEmail(email);

      alert('Profile settings saved successfully.');
    } catch (err) {
      console.error('Error saving profile settings:', err);
      alert('Failed to save profile settings.');
    }
  };

  const handleSubscribe = async () => {
    if (!email) {
      alert('Please update your email address!');
      return;
    }

    try {
      await axios.post('/api/subscribers/subscribe');
      setIsSubscribed(true);
      alert('Subscribed to email campaigns.');
    } catch (err) {
      console.error('Error subscribing to email campaigns:', err);
      alert('Failed to subscribe to email campaigns.');
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await axios.post('/api/subscribers/unsubscribe');
      setIsSubscribed(false);
      alert('Unsubscribed from email campaigns.');
    } catch (err) {
      console.error('Error unsubscribing from email campaigns:', err);
      alert('Failed to unsubscribe from email campaigns.');
    }
  };

  return (
    <div className='custom-profile'>
      <h1>Profile Settings</h1>
      <div className='custom-profile-section'>
        <div className='custom-image-upload'>
          <input
            type='file'
            accept='image/*'
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id='profilePictureInput'
          />
          <label
            htmlFor='profilePictureInput'
            className='custom-image-upload-label'
          >
            {profilePicture ? (
              <img
                src={profilePicture}
                alt='Profile'
                className='custom-profile-picture'
              />
            ) : (
              <div className='custom-add-image-placeholder'>Add Image</div>
            )}
          </label>
        </div>
        {rating !== null && !isAdmin && (
          <div className='custom-message-section'>
            <label htmlFor='rating'>Average Rating</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Rating value={rating} readOnly precision={0.1} />
              <Typography
                variant='body1'
                style={{ marginLeft: '8px', fontWeight: 'bold' }}
              >
                {rating.toFixed(1)}
              </Typography>
            </div>
          </div>
        )}
        <div className='custom-message-section'>
          <label htmlFor='email'>Email</label>
          <input
            id='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='custom-message-section'>
          <label htmlFor='phoneNumber'>Phone Number (mobile)</label>
          <input
            id='phoneNumber'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className='custom-message-section'>
          <label htmlFor='emailMessage'>Agent Biography (sent through Email to lead)</label>
          <textarea
            id='emailMessage'
            value={emailMessage}
            onChange={(e) => setEmailMessage(e.target.value)}
          />
        </div>
        <div className='custom-message-section'>
          <label htmlFor='smsMessage'>Agent Biography (sent through SMS to lead)</label>
          <textarea
            id='smsMessage'
            value={smsMessage}
            onChange={(e) => setSmsMessage(e.target.value)}
          />
        </div>
        <div className='profile-buttons'>
          <button className='custom-save-button' onClick={handleSave}>
            Save Settings
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
