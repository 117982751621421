import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from '../utils/axios';
import {useAuth} from "../AuthContext";

const HolidayPlanner = () => {
    const { user } = useAuth();
    const [formData, setFormData] = useState({
        title: "",
        dateFrom: "",
        dateTo: "",
        description: "",
    });
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(""); // Selected user for filtering
    const [holidays, setHolidays] = useState([]);
    const [error, setError] = useState("");

    const handleChange = async (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleUserFilter = async (e) => {
        const userId = e.target.value;
        setSelectedUser(userId);
        await fetchHolidays(userId); // Fetch holidays for the selected user
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            // Replace with your backend endpoint
            await axios.post("/api/holidays", formData);
            await fetchHolidays();
            alert("Holiday plan saved successfully!");
            setFormData({ title: "", dateFrom: "", dateTo: "", description: "" }); // Reset form
        } catch (err) {
            setError(err.response?.data?.error || "An error occurred");
        }
    };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/holidays/${id}`);
            await fetchHolidays();
            alert("Holiday plan canceled.");
            setHolidays(holidays.filter((holiday) => holiday._id !== id)); // Update list without the deleted holiday
        } catch (err) {
            console.error(err);
            alert("Failed to cancel the holiday plan.");
        }
    };

    useEffect(() => {
        fetchHolidays();
        if(user.role === 'admin') {
            fetchAgents();
        }
    }, [user]);

    const fetchAgents = async () => {
        try {
            const res = await axios.get('/api/users/agents');
            setUsers(res.data);
        } catch (err) {
            console.error(err);
            alert('Failed to fetch agents.');
        }
    };

    const fetchHolidays = async (userId) => {
        try {
            // Replace with your backend endpoint
            const endpoint = userId ? `/api/holidays/${userId}` : "/api/holidays";
            const response = await axios.get(endpoint);
            // Sort holidays by dateFrom (earliest to latest)
            const sortedHolidays = response.data.sort(
                (a, b) => new Date(a.dateFrom) - new Date(b.dateFrom)
            );
            setHolidays(sortedHolidays);
        } catch (err) {
            console.error(err);
            setError("Failed to fetch holidays.");
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom>
                Unavailability Planner
            </Typography>
            {user?.role !== 'admin' && (<Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
                <TextField
                    label="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Date From"
                    name="dateFrom"
                    type="datetime-local"
                    value={formData.dateFrom}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Date To"
                    name="dateTo"
                    type="datetime-local"
                    value={formData.dateTo}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button type="submit" variant="contained">
                    Save Holiday
                </Button>
            </Box>)}
            {/* Holiday List */}
            <Typography variant="h6" component="h6" gutterBottom sx={{ mt: 4 }}>
                Planned Unavailabilities
            </Typography>
            {user?.role === 'admin' && (<FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="user-filter-label" shrink>Filter by User</InputLabel>
                <Select
                    labelId="user-filter-label"
                    value={selectedUser}
                    onChange={handleUserFilter}
                >
                    <MenuItem value="">All Users</MenuItem>
                    {users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                            {user.username}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>)}
            {holidays.length === 0 ? (
                <Typography>No plans yet.</Typography>
            ) : (
                <List>
                    {holidays.map((holiday) => (
                        <React.Fragment key={holiday._id}>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDelete(holiday._id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={`${holiday.userId?.username}: ${holiday.title} (${new Date(
                                        holiday.dateFrom
                                    ).toLocaleDateString()} - ${new Date(
                                        holiday.dateTo
                                    ).toLocaleDateString()})`}
                                    secondary={holiday.description || "No description provided"}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            )}
        </Container>
    );
};

export default HolidayPlanner;
