import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import './Log.css';
import {Link} from "react-router-dom";


const Log = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const res = await axios.get('/api/logs');
        setLogs(res.data);
      } catch (err) {
        console.error('Error fetching logs:', err);
      }
    };

    fetchLogs();
  }, []);

  return (
    <div className="log">
      <h1>Log</h1>
      <ul className="log-list">
        {logs.map(log => (
          <li key={log._id} className="log-entry">
            <p>Lead ID: {log.leadId ? (<Link to={`/leads/${log.leadId._id}`}>{log.leadId._id}</Link>) : 'N/A'}</p>
            <p>Lead Name: {log.leadId ? log.leadId.name : 'N/A'}</p>
            {log.message} - {new Date(log.createdAt).toLocaleString()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Log;
